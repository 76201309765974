














import Vue, { PropOptions } from 'vue';

export type ColumnItemText = Blok & {
  headline: string;
  description: string;
  button: Blok[];
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<ColumnItemText>,
  },
});
